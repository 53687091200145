<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>店铺管理</span>
			</div>
			<div class="addModel">
				<el-button type="primary" @click="addShop()" icon="el-icon-plus">添加店铺</el-button>
			</div>
			<div>
				<sb-search
						@onSubmit="onSubmit"
						@reset="reset"
				>
				</sb-search>
			</div>
			<div class="shopcards">
				<el-table :data="tableData" border style="width: 100%; margin-top: 20px" v-loading="loading" row-key="shopId">
					<!--					<el-table-column type="index" label="序号" width="50" align="center">-->
					<!--					</el-table-column>-->
					<el-table-column align="center" label="平台" prop="platformName">
					</el-table-column>
					<el-table-column prop="shopName" label="店铺名称" align="center">
					</el-table-column>
					<el-table-column prop="shopUrl" label="店铺链接" align="center">
						<template slot-scope="scope">
							<div class="copy hoverHand" @click="copyLink(scope.row.shopUrl)">点击复制</div>

						</template>
					</el-table-column>
					<!--					<el-table-column align="center" label="发货地址" prop="addr">-->
					<!--					</el-table-column>-->
					<el-table-column align="center" label="后台截图" prop="shopPhoto">
						<template slot-scope="scope">
              <div style="width: 80px;margin: 0 auto">
                <sb-image :src="scope.row.shopWebsite" :src-list="[scope.row.shopWebsite]" width="80px" height="80px" ></sb-image>
              </div>
						</template>
					</el-table-column>
					<el-table-column align="center" label="店铺状态" prop="statusLabel">
					</el-table-column>
					<el-table-column prop="spReason" label="审核意见" align="center">
					</el-table-column>
					<el-table-column align="center" label="操作" prop="amount3">
						<template slot-scope="scope">
							<el-link type="primary" @click="editShop(scope.row)">修改店铺</el-link>
						</template>
					</el-table-column>
				</el-table>
				<sb-pagination
						:total="totalRow"
						@handleCurrentChange="handleCurrentChange"
						@handleSizeChange="handleSizeChange">
				</sb-pagination>
			</div>
		</el-card>
		<sb-model ref="model" :title="title" @successHandel="successHandel"></sb-model>
	</div>
</template>

<script>
	import sbPagination from '../../components/pagination/pagination.vue';
	import sbModel from './operationModel.vue';
	import SbImage from '../../components/image/image.vue';
	import SbSearch from './searForm'
	import {SHOP} from '../../api/base'

	export default {
		name: 'shopManagement',
		components: {
			sbPagination,
			sbModel,
			SbImage,
			SbSearch,
		},
		data() {
			return {
				title: '',
				loading: false,
				tableData: [
					// {
					// 	addr: "广东省深圳市福田区天安",
					// 	agentId: "9dd6bbe811ce455ab6f50fee5d4aacb2",
					// 	id: "0e393cb763044ee7b9c04e04d15b9d23",
					// 	platform: "淘宝/天猫",
					// 	shopName: "托尼潮色沙龙",
					// 	shopPhoto: "http://cdn.dianpujingling.com/jlbox/2020-12-16/ecfb8803-a88c-4ad5-86b8-892d8994f547.png",
					// 	shopUrl: "https://shop34908016.taobao.com/?spm=2013.1.1000126.3.9c2f4d24aF39n4",
					// 	spReason: "",
					// 	status: "审核通过",
					// 	vibratoNo: "",
					// },{
					// 	addr: "广东省深圳市福田区天安",
					// 	agentId: "9dd6bbe811ce455ab6f50fee5d4aacb2",
					// 	id: "0e393cb763044ee7b9c04e04d15b9d23",
					// 	platform: "淘宝/天猫",
					// 	shopName: "托尼潮色沙龙",
					// 	shopPhoto: "http://cdn.dianpujingling.com/jlbox/2020-12-16/ecfb8803-a88c-4ad5-86b8-892d8994f547.png",
					// 	shopUrl: "https://shop34908016.taobao.com/?spm=2013.1.1000126.3.9c2f4d24aF39n4",
					// 	spReason: "",
					// 	status: "审核通过",
					// 	vibratoNo: "",
					// },
				],
				requireForm: {
					pageSize: 10,
					pageIndex: 1,
					platformId:'',
					shopName:'',
				},
				totalRow: 0,
			}
		},

		beforeMount() {
			this.getList()
		},
		methods: {
			onSubmit(form){
				for (let item in form){
					this.requireForm[item] = form[item]
				}
				this.requireForm.pageIndex = 1;
				this.getList()
			},
			reset(){

			},
			successHandel() {
				this.requireForm.pageIndex = 1;
				this.getList()
			},
			getList() {
				this.loading = true;
				this.$axios.get(SHOP, this.requireForm).then(res => {
					this.loading = false;
					if (res.code == 200) {
						const data = res.data;
						this.tableData = data.data;
						this.requireForm.pageIndex = data.pageNumber;
						this.requireForm.pageSize = data.pageSize;
						this.totalRow = data.totalRow;
					}
				})
			}
			,
			editShop: function (row) {
				this.title = '编辑店铺';
				this.$refs.model.open(row)
			}
			,
			copyLink: function (copytext) {
				this.$copyText(copytext).then(() => {
					this.$mes({
						message: '复制成功'
					})
				}, () => {
					this.$mes({
						message: '无法复制',
						type: 'error'
					})
				})

			},
			addShop() {
				this.title = '绑定店铺'
				this.$refs.model.open()
			},
			handleSizeChange() {

			},
			handleCurrentChange(value) {
				this.requireForm.pageIndex = value;
				this.getList()
			},
		}
	}
</script>

<style lang="less" scoped>
	
	.shopcards /deep/ .el-table__header-wrapper th{
		background-color: #F4F5F8 !important;
	}
	.copy {
		text-decoration: underline;

	}

	.box-card {
		width: 980px;
	}

	.addModel {
		margin-bottom: 30px;

		.el-button--primary {
			color: white;
			background-color: @primaryColorQian  !important;
			border-color: @primaryColorQian;
		}

		.el-button--primary:hover {
			background-color: #ffa68f !important;
			border-color: #ffa68f;
		}
	}
</style>
